.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bg-gradient {
  background: rgb(67, 161, 255) !important;
  background: radial-gradient(circle, rgb(18 134 248) 35%, rgba(0, 90, 181, 1) 100%) !important;
}

.bottom-menus {
  display: none;
}

.form-control:focus {
  box-shadow: none !important;
}

.admin-panel-body-margin-top {
  margin-top: 120px;
}

th, td {
  font-size: 13px !important;
}

th {
  /* background-color: #1976d221 !important; */
  background: rgb(67, 161, 255) !important;
  background: radial-gradient(circle, rgb(18 134 248) 35%, rgba(0, 90, 181, 1) 100%) !important;
  color: #fff !important;
}

@media only screen and (max-width: 992px) {
  .mobile-banner {
    margin-top: 65px !important;
  }
  .d-none-on-mobile {
    display: none !important;
  }
  .bottom-menus {
    display: block !important;
  }
  .admin-panel-body-margin-top {
    margin-top: 60px !important;
  }
}